@mixin horizontal {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

@mixin vertical {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@mixin centered {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin children {
    display: flex;
    align-items: center;
    justify-content: center;
}
